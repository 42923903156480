import React from "react";
import Layout from "../components/layout";
import globalStyle from "../styles/global.module.css";
import membershipPreviewStyle from "./membership-preview.module.css";
import {Col, Row, Container, Button} from "react-bootstrap";
import {graphql} from "gatsby";
import VideoAudioControl from "../components/videoAudioControl";
import Img from "gatsby-image";

const MambershipPreviewPage = ({data}) => {

  return (
    <Layout className={membershipPreviewStyle.becomeAMemberContent}>
      <Container>
        <Row className={membershipPreviewStyle.membershipPreviewContent}>
          <Col>
            <h1 className={globalStyle.entryTitle}>Membership Preview</h1>

            <Row>
              {data.prenatalYogaIntro.edges.map(item => (
                <Col key={item.node.id} xs={12} md={6} sm={6} className={[membershipPreviewStyle.videoContent].join(' ')}>
                  <h3 className={membershipPreviewStyle.videoTitle}>Prenatal Yoga</h3>
                  <div className={membershipPreviewStyle.iframeContent}>
                    <iframe id={item.node.id} src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            title="Prenatal Yoga Intro"
                            controls controlsList="nodownload"
                            webkitallowfullscreen="true" mozallowfullscreen="true" >
                    </iframe>
                    <VideoAudioControl></VideoAudioControl>
                  </div>

                  <ul className={membershipPreviewStyle.list}>
                    <li>
                        Modified flow class
                    </li>

                    <li>
                        Modified prenatal vinyasa
                    </li>

                    <li>
                      Prenatal for the spine yoga
                    </li>

                    <li>
                      Easing leg cramps class
                    </li>

                    <li>
                      Preparing for birth
                    </li>
                  </ul>
                </Col>
              ))}
              {data.postPartumYogaIntro.edges.map(item => (
                <Col key={item.node.id} xs={12} md={6} sm={6} className={[membershipPreviewStyle.videoContent].join(' ')}>
                  <h3 className={membershipPreviewStyle.videoTitle}>Post Partum Yoga</h3>
                  <div className={membershipPreviewStyle.iframeContent}>
                    <iframe id={item.node.id} src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            title="Prenatal Yoga Intro"
                            controls controlsList="nodownload"
                            webkitallowfullscreen="true" mozallowfullscreen="true" >
                    </iframe>
                    <VideoAudioControl></VideoAudioControl>
                  </div>
                  <ul className={membershipPreviewStyle.list}>
                    <li>
                      Back to the mat
                    </li>
                    <li>
                      Post caesarean
                    </li>
                    <li>
                      Baby and me for energy and joy
                    </li>
                    <li>
                      Mom and baby move your body
                    </li>
                  </ul>
                </Col>
              ))}
            </Row>

            <Row>
              {data.babyMassageIntro.edges.map(item => (
                <Col key={item.node.id} xs={12} md={6} sm={6} className={[membershipPreviewStyle.videoContent].join(' ')}>
                  <h3 className={membershipPreviewStyle.videoTitle}>Baby Massage</h3>
                  <div className={membershipPreviewStyle.iframeContent}>
                    <iframe id={item.node.id} src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            title="Prenatal Yoga Intro"
                            controls controlsList="nodownload"
                            webkitallowfullscreen="true" mozallowfullscreen="true" >
                    </iframe>
                    <VideoAudioControl></VideoAudioControl>
                  </div>
                  <ul className={membershipPreviewStyle.list}>
                    <li>
                      For when you’re feeling down
                    </li>
                    <li>
                      To calm your mind
                    </li>

                    <li>
                      To prevent exhaustion
                    </li>
                    <li>
                      For fear of the future
                    </li>
                    <li>
                      To control your mood swings
                    </li>
                  </ul>
                </Col>
              ))}
              {data.interviewsWithExperts.edges.map(item => (
                <Col key={item.node.id} xs={12} md={6} sm={6} className={[membershipPreviewStyle.videoContent].join(' ')}>
                  <h3 className={membershipPreviewStyle.videoTitle}>Interviews With Experts</h3>
                  <div className={membershipPreviewStyle.iframeContent}>
                    <iframe id={item.node.id} src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            title="Interviews With Experts"
                            controls controlsList="nodownload"
                            webkitallowfullscreen="true" mozallowfullscreen="true" >
                    </iframe>
                    <VideoAudioControl></VideoAudioControl>
                  </div>
                  <ul className={membershipPreviewStyle.list}>
                    <li>
                      Chiropractic care for mama and baby
                    </li>

                    <li>
                      Mindful parenting
                    </li>

                    <li>
                      Maternal mental health discussion
                    </li>

                    <li>
                      Restore the core baby boot camp
                    </li>

                    <li>
                      Baby and toddler sleep best practices
                    </li>

                    <li>
                      Breastfeeding tips and strategies
                    </li>

                    <li>
                      Personal brand and fashion for the new mama
                    </li>

                    <li>
                      Marriage and intimacy after baby
                    </li>

                    <li>
                      Raising emotionally happy kids
                    </li>

                    <li>
                      Feeding your baby, toddler and the whole family
                    </li>

                    <li>
                      Prenatal and postpartum wellness and fitness
                    </li>
                  </ul>
                </Col>
              ))}
            </Row>

            <Row>
              {data.momMeditations.edges.map(item => (
                <Col key={item.node.id} xs={12} md={6} sm={6} className={[membershipPreviewStyle.videoContent].join(' ')}>
                  <h3 className={membershipPreviewStyle.videoTitle}>MOM Meditations</h3>
                  <div className={membershipPreviewStyle.iframeContent}>
                    <iframe id={item.node.id} src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            title="MOM Meditations"
                            controls controlsList="nodownload"
                            webkitallowfullscreen="true" mozallowfullscreen="true" >
                    </iframe>
                    <VideoAudioControl></VideoAudioControl>
                  </div>
                  <ul className={membershipPreviewStyle.list}>
                    <li>
                      Baby massage and massaging the legs & feet
                    </li>
                    <li>
                      Massaging baby's tummy and chest & arms
                    </li>
                    <li>
                      Massaging baby's face & back
                    </li>
                    <li>
                      Bringing it all together
                    </li>
                    <li>
                      Enjoy more courses with your 15% discount at Bambino&i
                    </li>
                  </ul>
                </Col>
              ))}
              <Col xs={12} md={6} sm={6} className={membershipPreviewStyle.wellnessContainer}>
                <h4 className={membershipPreviewStyle.videoTitle}>
                  Health &amp; Wellness
                </h4>
                <Img fluid={data.wellnessPhoto.childImageSharp.fluid} className={membershipPreviewStyle.wellnessImage}></Img>
                <ul
                  className={membershipPreviewStyle.list}
                  style={{ listStyleType: "none" }}
                >
                  <li>
                    Access your own workbook:
                    Beautiful Motherhood: The 6 Must Haves to Enjoy Your
                    Motherhood Journey

                    . All MOMS on Maternity Club Members receive a complimentary
                    Exploration Call ($145 value) with Joy Monet.
                  </li>
                </ul>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} sm={6} className={[membershipPreviewStyle.videoContent].join(' ')}>

              </Col>
              <Col xs={12} md={6} sm={6}></Col>
            </Row>

            <div className={membershipPreviewStyle.buttonContent}>
              <Button className={[globalStyle.btnPrimary, globalStyle.submitButtonOrange, membershipPreviewStyle.previewButton]} href='/become-a-member'>JOIN US</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    prenatalYogaIntro: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "prenatal-yoga-intro"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    postPartumYogaIntro: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "post-partum-yoga-intro"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    interviewsWithExperts: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "interviews-experts-intro"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    momMeditations: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "yoga-intro"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    babyMassageIntro: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "baby-massage-intro"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    wellnessPhoto: file(
      relativePath: { eq: "wellness/thiago-cerqueira-Wr3HGvx_RSM-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default MambershipPreviewPage
